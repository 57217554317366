.content-wrapper {
    background-color: #fff;
    margin: 24px;
    padding: 24px;
    height: 100%;
    min-height: 280px;

}

.page-with-breadcrumb-wrapper {
    padding: 0 24px 24px;
}

.breadcrumb-wrapper {
    margin: 16px 0;
}

.content-after-breadcrumb {
    background-color: #fff;
    margin: 0;
    padding: 24px;
    height: 100%;
}

.overview-menu {
    height: 100%;
    border-right: 0;
}

.overviewmap-menu .overview-menuitem {
    padding-right: 10px;
}

.overview-menuitem {
    display: flex;
    align-items: center;
}

.overview-menuitem-name {
    flex: 1 0 auto;
}

.overview-menuitem-switch {

}


.category-wrapper {
    background-color: #fff;
    margin: 24px;
    padding: 24px;
}
.header-logo {
    float: left;
    margin-right: 20px;
}

.header-logo a {
    color: white;
}

.header-logo img {
    height: 29px;
}

.fedmon-header-menu {
    line-height: 63px;
}

.header {
    display: flex;
    justify-content: center;
}

.header-status {
    padding-left: 16px;
    padding-right: 16px;
    /* background: rgba(255,255,255,.2);*/
    margin-left: auto;
    /*  margin-top: 16px;*/
}

@media screen and (max-width: 767px) {
    .header-status {
        display: none;
    }
}
.selftest-wrapper{
    margin-top: 2em;
    margin-left: 5%;
    margin-right: 5%;
}

.selftest-alert-content{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: row;
}

.selftest-moreinfo {
    margin-top: 1em;
}

.selftest-status{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
}
.selftest-status-icon {
    width: 55px;
    -webkit-animation: pulsate 1s infinite alternate;
            animation: pulsate 1s infinite alternate;
    margin-bottom: 10px;
}

.selftest-alert-content .selftest-status-icon {
    width: 30px;
    margin-right: 10px;
}

.selftest-info {
    text-align:center;
}

@-webkit-keyframes pulsate {
    to {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
}

@keyframes pulsate {
    to {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
}

.success .selftest-status-icon{
    fill: #52c41a; /*green-6 */
}

.error .selftest-status-icon{
    fill: #fa541c; /*volcano-6*/
}

.warning .selftest-status-icon{
    fill: #fa8c16; /*orange-6 */
}

.unavailable .selftest-status-icon{
    fill: #bfbfbf /* grey-6 */;
}

.selftest-header-status{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: row;
}
.selftest-status-icon {
    width: 55px;
    -webkit-animation: pulsate 1s infinite alternate;
            animation: pulsate 1s infinite alternate;
    margin-bottom: 10px;
}

.selftest-header-status > .selftest-status-icon{
    width: 40px;
}

.selftest-header-info {
    margin-left: 8px;
}

.selftest-header-info {
    color: rgba(255, 255, 255, 0.65);
}

.testbed-wrapper {
    background: #fff;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    margin-bottom: 10px;
    margin-left: 2px;
    margin-right: 2px;
    min-height: 82px;
}

.testbed-failure {
    background: #ffb4a4;
}

.testbed-success {
    background: #82CA9D;
}

.testbed-warning {
    background: #ffd3a4;
}

.testbed-unavailable {
    background: #e7e7e7;
}

.testbed-wrapper .testbed-title,
.testbed-wrapper .testbed-title a,
.testbed-wrapper .testbed-title a:hover {
    /*border-bottom: 1px solid #d7d7d7;*/
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 200;
    padding: 7px 10px 4px;
    text-align: center;
    white-space: nowrap;
}

.testbed-title div {
    display: inline;
}

 .health-box {
    width: 35px;
    height: 25px;
     margin-top: 0.1em;
     margin-right: 0.1em;
     float: right;
}

 .health-questionmark{
     font-size:0.8em;
     line-height: 1.6em;
     /*line-height: 26px;*/
     z-index: 2;
     color: white;
 }

 .health-icon{
     position: relative;
     display: inline-block;
     width: 1.5em;
     height: 2em;
     line-height: 2em;
     vertical-align: middle;

     margin-top: 7px;
 }

 .health-icon-icon {
     position:absolute;
     width: 100%;
     text-align: center;

     font-size: 1.5em;
     z-index: 1;
 }

 .health-icon-text {
     position:absolute;
     width: 100%;
     text-align: center;

     line-height: 1.8em;

     font-size:0.7em;
     z-index: 2;
     color: white;
 }

.testbed-wrapper .testbed-stage {
    overflow: hidden;
    padding: 5px 10px;
    position: relative;
}

.testbed-wrapper .testbed-notes {
    background: #fbfbfb;
    border-top: 1px solid #e2e2e2;
    color: #808080;
    font-size: 12px;
    padding: 8px 10px 5px;
}


.testbed-status-container {
    max-width: 55px;
}

.testbed-status-indicators {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.testbed-status-indicators > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /*width: 20%;*/
}

.status-indicator {
    width: 60%;
    display: inline-block;
    text-align: center;
    font-size: 12px;
}

.status-icon {
    width: 60%;
    display: inline-block;
}

.status-icon-success {
    fill: #00aa3f;
}

.testbed-health-success .health-icon {
    color: #00aa3f;
}

.status-icon-failure {
    fill: #ea2900;
}

.testbed-health-failure .health-icon {
    color: #ea2900;
}

.testbed-health-warning .health-icon {
    color: #ea7a00;
}

.status-icon-warning {
    fill: #ea7a00;
}

.status-icon-unavailable {
    fill: lightgray;
}

.testbed-health-unavailable .health-icon {
    color: lightgray;
}

.late-icon {
    fill: #ea2900;
    -webkit-animation: latefade 1s infinite alternate;
            animation: latefade 1s infinite alternate;
}

@-webkit-keyframes latefade {
    0% {opacity: 1;}
    40% {opacity: 1;}
    100%  { opacity:.4; }
}

@keyframes latefade {
    0% {opacity: 1;}
    40% {opacity: 1;}
    100%  { opacity:.4; }
}


.testbed-health {
}

.testbed-api {
}

.testbed-internal-status {
}

.testbed-login {
}

.resource-gauges {
    /*width: 20%;*/
    min-width: 55px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.resource-gauge {
    width: 45px;
    height: 45px;
}


/*.update-timer {*/
  /*border: 0 solid #d7d7d7;*/
  /*border-top-width: 1px;*/
  /*width: 100%;*/
  /*margin: 10px 0;*/
/*}*/

/*@keyframes timer-width {*/
  /*from {width: 100%;}*/
  /*to {width: 0%;}*/
/*}*/
.testbedtilecontainer-wrapper {
    width: 350px;
    height: 90px;
}

.map {
    min-height: 700px;
}
.testbed-logo {
    height:100%;
    display: flex;
    align-items:center;
    justify-content: center;
}

.testbed-logo img {
    max-width: 75px;
    max-height: 75px;
}

.testbed-logo .placeholder {
    -webkit-filter: grayscale(100%) opacity(50%);
            filter: grayscale(100%) opacity(50%);

}

.action-item{
    padding-right: 1em;
}

.location-leaflet-container {
    height: 400px;
    width: 100%;
    margin: 0 auto;
}
/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

.react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-filled {
  fill: #8cc665;
}

/*
 * Github color scale
 */

.react-calendar-heatmap .color-github-0 {
  fill: #eeeeee;
}
.react-calendar-heatmap .color-github-1 {
  fill: #d6e685;
}
.react-calendar-heatmap .color-github-2 {
  fill: #8cc665;
}
.react-calendar-heatmap .color-github-3 {
  fill: #44a340;
}
.react-calendar-heatmap .color-github-4 {
  fill: #1e6823;
}

/*
 * Gitlab color scale
 */

.react-calendar-heatmap .color-gitlab-0 {
  fill: #ededed;
}
.react-calendar-heatmap .color-gitlab-1 {
  fill: #acd5f2;
}
.react-calendar-heatmap .color-gitlab-2 {
  fill: #7fa8d1;
}
.react-calendar-heatmap .color-gitlab-3 {
  fill: #49729b;
}
.react-calendar-heatmap .color-gitlab-4 {
  fill: #254e77;
}


/*
* Aggregate status scale
*/

.react-calendar-heatmap .aggregate-color-0 {
  fill: #9d171b;
}
.react-calendar-heatmap .aggregate-color-1 {
  fill: #df575a;
}
.react-calendar-heatmap .aggregate-color-2 {
  fill: #e1a958;
}
.react-calendar-heatmap .aggregate-color-3 {
  fill: #dfe157;
}
.react-calendar-heatmap .aggregate-color-4 {
  fill: #4cb747;
}
.selected-nodes > div {
    font-family: monospace;
    word-break: break-word;
}
.selected-nodes > div:last-child{

    margin-bottom: 16px;
}
.finterop-testsuite-table {
}

.finterop-testsuite-table th {
    background-color: #f5f5f5;
 /*   color: white; */
    text-align: center;
    border: 1px solid #e8e8e8;

  /*  max-width: 170px;*/
}

.finterop-testsuite-table td{
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border: 1px solid #e8e8e8;
    text-align: center;
    color: white;
    min-width: 80px;
}
.finterop-testsuite-table td a{
    color: white;
}

.finterop-testsuite-table th:not(:first-child) {
    font-family: monospace, monospace;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.finterop-testsuite-table td:first-child{
    padding-left: 1em;
    padding-right: 1em;
    color: #595959;
    font-family: monospace, monospace;
}


.verdict-pass {
    background-color: #52c41a;
}

.verdict-inconclusive {
    background-color: #fa8c16;
}
.verdict-fail {
    background-color: #fa541c;
}

.verdict-error {
    background-color: #f5222d;
}

.verdict-None {
    background-color: #1890ff;
}

.finterop-testsuite-table tr:hover td:first-child{
    background-color: #e8e8e8; /* grey-4 */
}

.finterop-testsuite-table tr:hover td.verdict-pass{
    background-color: #389e0d;
}

.finterop-testsuite-table tr:hover td.verdict-inconclusive {
    background-color: #d46b08;
}
.finterop-testsuite-table tr:hover td.verdict-fail {
    background-color: #d4380d;
}

.finterop-testsuite-table tr:hover td.verdict-error {
    background-color: #cf1322;
}

.finterop-testsuite-table tr:hover td.verdict-None {
    background-color: #096dd9;
}

.finterop-testsuites-anchor{
    padding-top: 24px;
    margin-left: 24px;
   background-color: #f0f2f5;
}

.finterop-testsuites-anchor .ant-anchor-link-title {
    overflow: visible;
}
.icon-header-image {
    font-size: 400%;
}

.banner .img-thumbnail {
    position: relative;
    width: 165px;
    height: 165px;
}

.banner img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 155px;
}

.resultdetail-header .antd-pro-page-header-content {
    flex-basis: 600px;
}

.resultdetail-header .antd-pro-page-header-extraContent {
    flex-basis: 300px;
    flex-grow: 1;

    margin-left: 10px;
}

.steps-timeline a {
    color: #262626;
}

.result-tab-image {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    vertical-align: text-bottom;
    -webkit-filter: grayscale(90%);
            filter: grayscale(90%);
}

.ant-tabs-tab-active .result-tab-image {
    -webkit-filter: none;
            filter: none;
}
.apicalls-anchor .ant-anchor-link-title {
    overflow: visible;
}


.loglines-table table {
    table-layout: fixed;
}

.loglines-table td{
    vertical-align: top;
}


/*.logline-row-note{*/
/*background-color: #e6f7ff;*/
/*}*/

.logline-row-exception {
    background-color: #fff2e8;
}

.logline-row-warn {
    background-color: #fff7e6;
}
.call-info {
    display: flex;
}

.call-info-name {
    font-weight: bold;
    width: 180px;
    margin-right: 8px;
    text-align: right;
}

.call-info-value {
    font-family: monospace;
}

.call-prettify {
    margin-left: 48px;
    font-weight: 400;
    font-size: smaller;
}

.call-content {
    padding: 8px;
    border: #e8e8e8 1px solid;
    white-space: pre-wrap;
    font-size: small;
    min-height: 3em;
}

.call-content-empty {
   /* background: repeating-linear-gradient(45deg, #fafafa, #fafafa 10px, #f5f5f5 10px, #f5f5f5 20px);*/
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #fafafa 10px, #fafafa 20px);
}

.geni-response-description {
    margin-bottom: 12px;
    font-size: smaller;
}

.description{
    margin-bottom: 16px;
}
.description-title {
    font-weight: bold;

}

.code-description .description-value {
    font-family: monospace;
    word-break: break-word;
}
.logline {
    display: flex;
}

.logline-timestamp {
    font-family: monospace;
    width: 120px;
    margin-right: 8px;
    text-align: right;
    flex-shrink: 0;
}

.logline-type {
    font-family: monospace;
    width: 96px;
    margin-right: 8px;
    text-align: right;
    flex-shrink: 0;
}

.logline-text {
    flex-shrink: 1;
}

.call-prettify {
    margin-left: 48px;
    font-weight: 400;
    font-size: smaller;
}

.call-content {
    padding: 8px;
    border: #e8e8e8 1px solid;
    white-space: pre-wrap;
    font-size: small;
    min-height: 3em;
}

.call-content-empty {
    /* background: repeating-linear-gradient(45deg, #fafafa, #fafafa 10px, #f5f5f5 10px, #f5f5f5 20px);*/
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #fafafa 10px, #fafafa 20px);
}

.call-info {
    display: flex;

}

.call-info-name {
    font-weight: bold;
    width: 180px;
    margin-right: 8px;
    text-align: right;
}

.call-info-value {
    font-family: monospace;
}

.geni-response-description {
    margin-bottom: 12px;
    font-size: smaller;
}

.apicalls-anchor .ant-anchor-link-title {
    overflow: visible;
}

.apicalls-anchor {
    padding-top: 24px;
    margin-left: 24px;
    background-color: #f0f2f5;
}

.description {
    margin-bottom: 16px;
}

.description-title {
    font-weight: bold;

}

.code-description .description-value {
    font-family: monospace;
    word-break: break-word;
}

.test-steps .ant-steps-item-title {
    font-weight: 500;

}

.test-steps .ant-steps-item-title a {
    color: #262626;

}

.description{
    margin-bottom: 16px;
}
.description-title {
    font-weight: bold;

}

.code-description .description-value {
    font-family: monospace;
    word-break: break-word;
}

.test-steps .ant-steps-item-title {
    font-weight: 500;

}

.test-steps .ant-steps-item-title a {
    color: #262626;

}
.logline {
    display: flex;
}

.logline-timestamp {
    font-family: monospace;
    width: 120px;
    margin-right: 8px;
    text-align: right;
    flex-shrink: 0;
}

.logline-type {
    font-family: monospace;
    width: 96px;
    margin-right: 8px;
    text-align: right;
    flex-shrink: 0;
}

.logline-text {
    flex-shrink: 1;
}

/*.logline-row-note{*/
    /*background-color: #e6f7ff;*/
/*}*/

.logline-row-exception{
    background-color: #fff2e8;
}


.logline-row-warn{
    background-color: #fff7e6;
}

.ansible-part {
    font-family: monospace;
    white-space: pre-wrap;
}

.other-part {
    font-family: monospace;
    color: #8c8c8c;
    white-space: pre-wrap;
}


.ansible-output {
    white-space: pre;
    font-size: small;
}

.parts-anchor{
    padding-top: 24px;
    margin-left: 24px;
    background-color: #f0f2f5;
}


.parts-anchor .ant-anchor-link-title {
    overflow: visible;
}

.testinstance-results-table {
    max-width: 900px;
}

.testresult-summary-success {
    background-color: #b7eb8f; /* green-3 */
}

.testresult-summary-warning {
    background-color: #ffd591; /* orange-3 */
}

.testresult-summary-failure {
    background-color: #ffbb96; /* volcano-3*/
}

.testresult-summary-cancelled {
    background-color: #91d5ff; /* blue-3 */
}

.testinstancehistory-actions {
    list-style: none;
    padding: 0;
}

/*.testinstancehistory-actions:before,*/
/*.testinstancehistory-actions:after {*/
/*content: "";*/
/*display: table;*/
/*}*/

.testinstancehistory-actions > li {
    float: left;
    /* text-align: center;*/
    padding-right: 12px;
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.45);
}

.testinstancehistory-actions > li:not(:last-child){
    border-right: 1px solid #bfbfbf;
}

.testinstancehistory-actions > li > span {
    /*  display: inline-block;*/

    font-size: 14px;
    /*    cursor: pointer;*/
    /*   line-height: 22px;*/
    /*  min-width: 32px;*/
    /*   position: relative;*/
}

.testinstancehistory-actions > li > span:hover {
    color: #1890ff;
    transition: color .3s;
}

.testinstancehistory-actions > li > span a {
    /*color: rgba(0, 0, 0, 0.45);*/
    line-height: 22px;
    display: inline-block;
    /*  width: 100%;*/
}
.wilab-result-wrapper {

}
.wilab-result {
    background: #fff;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
    min-height: 82px;
}

.wilab-summary-success {
    background-color: #b7eb8f; /* green-3 */
}

.wilab-summary-warning {
    background-color: #ffd591; /* orange-3 */
}

.wilab-summary-failure {
    background-color: #ffbb96; /* volcano-3*/
}

.wilab-summary-cancelled {
    background-color: #91d5ff; /* blue-3 */
}

.extra-data {
    font-family: monospace;
}

.wilab-chart{
    height: 16px;
    text-align: center;
    color: white;
    font-size: 0.8em;
    min-width: 16px;
    transition: height .3s, line-height .3s;
}

.wilab-chart:hover {
    height: 24px;
    line-height: 24px;
}

.wilab-chart-success {
    background-color: #73d13d;
}
.wilab-chart-success:hover {
    background-color: #389e0d;
}

.wilab-chart-warning {
    background-color: #ffa940;
}
.wilab-chart-warning:hover {
    background-color: #fa8c16;

}

.wilab-chart-failure {
    background-color: #fa541c;
}
.wilab-chart-failure:hover {
    background-color: #d4380d;
}
/*body {*/
  /*margin: 0;*/
  /*padding: 0;*/
  /*font-family: sans-serif;*/
  /*!*background-color: #f2f2f2;*!*/
/*}*/

/*.navbar {*/
  /*margin-bottom: 0;*/
/*}*/

