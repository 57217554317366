.header-logo {
    float: left;
    margin-right: 20px;
}

.header-logo a {
    color: white;
}

.header-logo img {
    height: 29px;
}

.fedmon-header-menu {
    line-height: 63px;
}

.header {
    display: flex;
    justify-content: center;
}

.header-status {
    padding-left: 16px;
    padding-right: 16px;
    /* background: rgba(255,255,255,.2);*/
    margin-left: auto;
    /*  margin-top: 16px;*/
}

@media screen and (max-width: 767px) {
    .header-status {
        display: none;
    }
}