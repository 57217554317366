.testbed-wrapper {
    background: #fff;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    margin-bottom: 10px;
    margin-left: 2px;
    margin-right: 2px;
    min-height: 82px;
}

.testbed-failure {
    background: #ffb4a4;
}

.testbed-success {
    background: #82CA9D;
}

.testbed-warning {
    background: #ffd3a4;
}

.testbed-unavailable {
    background: #e7e7e7;
}

.testbed-wrapper .testbed-title,
.testbed-wrapper .testbed-title a,
.testbed-wrapper .testbed-title a:hover {
    /*border-bottom: 1px solid #d7d7d7;*/
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 200;
    padding: 7px 10px 4px;
    text-align: center;
    white-space: nowrap;
}

.testbed-title div {
    display: inline;
}

 .health-box {
    width: 35px;
    height: 25px;
     margin-top: 0.1em;
     margin-right: 0.1em;
     float: right;
}

 .health-questionmark{
     font-size:0.8em;
     line-height: 1.6em;
     /*line-height: 26px;*/
     z-index: 2;
     color: white;
 }

 .health-icon{
     position: relative;
     display: inline-block;
     width: 1.5em;
     height: 2em;
     line-height: 2em;
     vertical-align: middle;

     margin-top: 7px;
 }

 .health-icon-icon {
     position:absolute;
     width: 100%;
     text-align: center;

     font-size: 1.5em;
     z-index: 1;
 }

 .health-icon-text {
     position:absolute;
     width: 100%;
     text-align: center;

     line-height: 1.8em;

     font-size:0.7em;
     z-index: 2;
     color: white;
 }

.testbed-wrapper .testbed-stage {
    overflow: hidden;
    padding: 5px 10px;
    position: relative;
}

.testbed-wrapper .testbed-notes {
    background: #fbfbfb;
    border-top: 1px solid #e2e2e2;
    color: #808080;
    font-size: 12px;
    padding: 8px 10px 5px;
}


.testbed-status-container {
    max-width: 55px;
}

.testbed-status-indicators {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.testbed-status-indicators > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /*width: 20%;*/
}

.status-indicator {
    width: 60%;
    display: inline-block;
    text-align: center;
    font-size: 12px;
}

.status-icon {
    width: 60%;
    display: inline-block;
}

.status-icon-success {
    fill: #00aa3f;
}

.testbed-health-success .health-icon {
    color: #00aa3f;
}

.status-icon-failure {
    fill: #ea2900;
}

.testbed-health-failure .health-icon {
    color: #ea2900;
}

.testbed-health-warning .health-icon {
    color: #ea7a00;
}

.status-icon-warning {
    fill: #ea7a00;
}

.status-icon-unavailable {
    fill: lightgray;
}

.testbed-health-unavailable .health-icon {
    color: lightgray;
}

.late-icon {
    fill: #ea2900;
    animation: latefade 1s infinite alternate;
}

@keyframes latefade {
    0% {opacity: 1;}
    40% {opacity: 1;}
    100%  { opacity:.4; }
}


.testbed-health {
}

.testbed-api {
}

.testbed-internal-status {
}

.testbed-login {
}

.resource-gauges {
    /*width: 20%;*/
    min-width: 55px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.resource-gauge {
    width: 45px;
    height: 45px;
}

